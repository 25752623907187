import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.toggleNav = this.toggleNav.bind(this)
    this.state = {
      navCollapsed: true,
      breakpointSmall: true,
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
    window.addEventListener('scroll', this.collapseNav.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions() {
    if (window.innerWidth <= 736) {
      this.setState({ breakpointSmall: true })
    } else {
      this.setState({ breakpointSmall: false })
    }
  }

  collapseNav() {
    this.setState({ navCollapsed: true })
  }

  toggleNav() {
    const currentState = this.state.navCollapsed
    this.setState({ navCollapsed: !currentState })
    console.log('this.state.breakpointSmall', this.state.breakpointSmall)
  }

  render() {
    return (
      <nav
        id="nav"
        className={
          this.state.breakpointSmall
            ? this.state.navCollapsed
              ? 'collapse'
              : ''
            : this.props.sticky
            ? 'alt'
            : ''
        }
        // className={this.props.sticky ? 'alt' : ''}
      >
        <div className="navToggle" onClick={this.toggleNav}>&#9776;</div>
        <Scrollspy
          items={['intro', 'methods', 'findings', 'whats-next']}
          currentClassName="is-active"
          offset={-300}
        >
          <li>
            <Scroll type="id" element="intro">
              <a href="#">Introduction</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="methods">
              <a href="#">Methods</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="findings">
              <a href="#">Findings</a>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="whats-next">
              <a href="#">What's Next</a>
            </Scroll>
          </li>
        </Scrollspy>
      </nav>
    )
  }
}
export default Nav
